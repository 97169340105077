.nav {
  height: 60px;
  box-shadow: 0px 1px 8px #888888;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
  padding: 0 15px;
}
.logo {
  height: 60px;
}
.icon {
  flex: 0.02 1;
}
.menu {
  flex: 1;
  display: inline-flex;
  justify-content: end;
}
@media (max-width: 800px) {
  .nav {
    height: 40px;
  }
  .logo {
    height: 40px;
  }
  .icon {
    flex: 1;
  }
  .menu {
    display: none;
  }
}
