.footer {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-bottom: 60px;
  }
  .name {
    font-size: 20px;
    padding: 20px 30px 15px;
    color: white;
  }
  
  .iconContainer {
    width: 20%;
    height: 50px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    .iconContainer {
      width: 60%;
    }
  }